<template>
  <div class="flexC" style="align-items: center">
    <img alt="Brew Ninja logo" src="../assets/BrewNinja.png" style="height: 50vh" />
    <!-- <div>{{ getWidth() }}</div> -->
    <!-- <br /> -->
    <div class="err">&nbsp;{{ msg }}</div>
    <!-- <br /> -->
    <div class="center mywidth">
      <b>Логин</b>
      <input ref="uname" type="text" v-model="uname" />
    </div>
    <br />
    <div class="center mywidth">
      <b>Пароль</b>
      <input autocomplete="off" @keydown.enter="login()" type="password" v-model="upass" />
    </div>
    <br />
    <div class="center mywidth">
      <button class="btn" @click="login()">Войти</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mywidth {
  width: 15rem;
}
</style>

<script>
import { request } from "@/components-js/requestSrv";
import { lS } from "@/components-js/localStorage";
import {mapActions} from "vuex";
import {ACTION_TYPES} from "@/store/const";
const loStorage = new lS();

export default {
  name: "Login",
  data: function () {
    return {
      uname: null,
      upass: null,
      msg: null,
    };
  },
  mounted() {
    this.$refs.uname.focus();
    loStorage.clear();
  },
  methods: {
    ...mapActions({ storeLogin:ACTION_TYPES.LOGIN }),
    login: async function () {
      const res = await request("api/auth/login", "POST", {
        uname: this.uname,
        upass: this.upass,
      });

      if (res != "Login error") {
        loStorage.set(res.token);
        this.storeLogin();
        this.$router.push({ name: "Today" });
      } else {
        this.msg = "неверный логин или пароль";
        setTimeout(() => {
          this.msg = null;
        }, 2000);
      }
    },
    // getWidth() {
    //   return window.innerWidth;
    // },
  },
};
</script>
